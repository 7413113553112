<template>
  <div class="position-relative table-responsive">
    <table class="table b-table table-sm table-bordered">
    <thead class="thead-dark">
      <tr>
        <th>#</th>
        <th v-if="categoryOtherOptionSlugs.includes('label_size')">Размер бирки</th>
        <th v-if="categoryOtherOptionSlugs.includes('size')">Размер</th>
        <th>Состояние</th>
        <th v-if="categoryOtherOptionSlugs.includes('weight')">Вес (кг)</th>
        <th v-if="categoryOtherOptionSlugs.includes('qty')">Кол-во</th>
        <th>Дата создания</th>
        <th colspan="3">Действия</th>
      </tr>
    </thead>
    <tbody>
    <template v-for="specification in specifications">
      <tr :key="specification.id">
        <td>{{ specification.id }}</td>
        <td v-if="categoryOtherOptionSlugs.includes('label_size')">{{ specification.label_size_name }}</td>
        <td class="text-nowrap" v-if="categoryOtherOptionSlugs.includes('size')">
          {{ specification.size_model.name }}
          <span
              v-if="specification.size_model.name !== specification.size_model.universal && specification.size_model.universal"
          >
          ({{ specification.size_model.universal }})
        </span>
          <span>
          <feather-icon
              :id="specification.id + 'popover'"
              icon="InfoIcon"
              size="16"
              class="align-text-top"
          />
          <b-popover
              custom-class="size-popover"
              :target="specification.id + 'popover'"
              triggers="hover"
              placement="top"
          >
            <template #title>Соответствие размерам</template>
              <div class="row text-nowrap mb-1">
                <div class="col">EU: {{ specification.size_model.eu }}</div>
                <div class="col">IT: {{ specification.size_model.it }}</div>
                <div class="col">UA: {{ specification.size_model.ua }}</div>
              </div>
              <div class="row text-nowrap mb-1">
                <div class="col">DE: {{ specification.size_model.de }}</div>
                <div class="col">UK: {{ specification.size_model.uk }}</div>
                <div class="col">US: {{ specification.size_model.us }}</div>
              </div>
              <div class="row text-nowrap">
                <div class="col">CA: {{ specification.size_model.ca }}</div>
                <div class="col">China: {{ specification.size_model.china }}</div>
              </div>
          </b-popover>
        </span>
        </td>
        <td>{{ specification.condition_name }}</td>
        <td v-if="categoryOtherOptionSlugs.includes('weight')">{{ specification.weight / 1000 }}</td>
        <td v-if="categoryOtherOptionSlugs.includes('qty')">{{ specification.set_qty }}</td>
        <td>{{ specification.created_at | formattedDate }}</td>
        <td v-if="specification.arrival_products_count < 1 && specification.editable">
          <router-link
              :to="{ name: 'specification-update', params: { id: specification.id } }"
              class="btn btn-warning btn-sm btn-block"
          >
            Редактировать
          </router-link>
        </td>
        <td class="text-center">
          <button class="btn btn-info btn-sm float-left" @click="printBarcode(specification.id)">
            Штрихкод
          </button>
        </td>
        <td v-if="specification.arrival_products_count < 1 && specification.editable">
          <feather-icon
              icon="TrashIcon"
              size="16"
              @click="removeSpecification(specification.id)"
              class="align-text-top text-danger cursor-pointer"
          />
        </td>
      </tr>
      <tr :key="`${specification.id}-2`" class="bottom-row" v-if="categoryMeasurementOptions.length > 0">
        <td :colspan="specification.editable ? 9 : 8">Мерки: {{ specification.full_measurements }}</td>
      </tr>
    </template>
    </tbody>
  </table>
  </div>
</template>
<script>

import { BPopover } from 'bootstrap-vue';

export default {
  name: 'SpecificationDetails',
  components: { BPopover },
  props: ['specifications', 'categoryId'],
  data() {
    return {
      categoryOptions: [],
      categoryMeasurementOptions: [],
      categoryOtherOptionSlugs: [],
    };
  },
  async mounted() {
    await this.getCategorySpecificationOptions();
  },
  methods: {
    async getCategorySpecificationOptions() {
      this.categoryOptions = (await this.$api.categories.specOptions(this.categoryId)).data;
      this.categoryMeasurementOptions = this.categoryOptions.filter((option) => option.product_group_id === 5);
      this.categoryOtherOptionSlugs = this.categoryOptions.filter((option) => option.product_group_id !== 5)?.map((option) => option.slug);
    },
    async printBarcode(id) {
      const content = (await this.$api.productSpecifications.label(id)).data;
      const win = this.openWindow();

      win.document.write(content);
    },
    openWindow() {
      let windowRef = null;
      windowRef = window.open('/print', '_blank');
      if (!windowRef.opener) {
        windowRef.opener = this;
      }
      windowRef.focus();

      return windowRef;
    },
    async removeSpecification(specificationId) {
      try {
        await this.$api.productSpecifications.remove(specificationId);
        await this.$emit('refresh');
      } catch (e) {
        this.$bvToast.toast(`Ошибка при удалении спецификации. ${e.response.data.message}`, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
};

</script>

<style scoped lang="scss">

</style>
