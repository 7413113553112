<template>
  <b-modal
      id="modal-manage-images"
      title="Добавление фотографий"
      ok-title="Сохранить"
      @ok="uploadFiles"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="row mb-2">
      <div class="col-12">
        <div class="demo-inline-spacing mb-2">
          <b-form-radio v-model="form.type" plain name="type" value="clear">
            Чистовые фото
          </b-form-radio>
          <b-form-radio v-model="form.type" plain name="type" value="draft">
            Черновые фото
          </b-form-radio>
        </div>
        <b-form-file
            v-model="form.files"
            placeholder="Выберите файлы или перетяните ..."
            drop-placeholder="Перетяните файлы сюда..."
            browse-text="Выбрать файлы"
            multiple
        />
        <div class="demo-inline-spacing">
          <b-form-radio
              v-model="form.orientation"
              plain
              name="orientation"
              value="vertical"
          >
            Вертикальные фото
          </b-form-radio>
          <b-form-radio
              v-model="form.orientation"
              plain
              name="orientation"
              value="horizontal"
          >
            Горизонтальные фото
          </b-form-radio>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BFormFile, BFormRadio } from 'bootstrap-vue';

export default {
  name: 'AddImagesModal',
  components: { BModal, BFormFile, BFormRadio },
  data() {
    return {
      form: {
        orientation: 'vertical',
        type: 'clear',
        files: null,
      },
    };
  },
  methods: {
    async uploadFiles() {
      const formData = new FormData();
      formData.append('orientation', this.form.orientation);
      formData.append('type', this.form.type);
      this.form.files.forEach((file) => {
        formData.append('files[]', file);
      });
      try {
        await this.$api.products.uploadImages(this.$route.params.id, formData);
      } catch (e) {
        this.$bvToast.toast(`Ошибка при добавлении фотографии. ${e.response.data.message}`, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
