<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body p-1">
                  <button class="btn btn-outline-primary mb-1 mb-md-0 mr-1 btn-sm-block" @click="downloadImages">
                    <feather-icon icon="DownloadIcon"/>
                    <span>Скачать фото</span>
                  </button>
                  <router-link
                      :to="{ name: 'product-edit', params: { id: product.id } }"
                      v-if="product && product.editable"
                      class="btn btn-warning mb-1 mb-md-0 mr-1 btn-sm-block"
                  >
                    Редактировать товар
                  </router-link>
                  <button
                      v-if="product && product.editable"
                      v-b-modal.modal-manage-images
                      class="btn btn-success btn-sm-block"
                  >
                    Добавить фото
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row invoice-add">
            <div class="col-12 col-md-7">
              <div class="card" v-if="product">
                <div class="card-body">
                  <h6>{{ product.category.path }} > {{ product.category.name }}</h6>
                  <hr />
                  <div class="row my-2">
                    <div class="col">
                      <h4>
                        {{ product.name }} {{ product.brand_name }}
                      </h4>
                    </div>
                    <div class="col-auto">Артикул:&nbsp;<span>{{ product.sku }}</span></div>
                  </div>
                  <h5>Описание</h5>
                  <p class="card-text">{{ product.description || 'Нет описания' }}</p>
                  <hr/>
                  <h5>Характеристики товара</h5>
                  <div class="position-relative table-responsive">
                    <table class="table b-table table-sm table-warning table-bordered">
                      <thead class="thead-dark">
                      <!---->
                      <tr>
                        <th>#</th>
                        <th>Группа</th>
                        <th>Вид</th>
                        <th>Название</th>
                        <th>Значение</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="dictionary in dictionaries" :key="dictionary.id" class="font-small-3">
                        <td>{{ dictionary.id }}</td>
                        <td>
                          {{
                            dictionary.product_option && dictionary.product_option.product_option_group
                                ? dictionary.product_option.product_option_group.name
                                : '-'
                          }}
                        </td>
                        <td>{{ dictionary.product_option ? dictionary.product_option.name : '-' }}</td>
                        <td>{{ dictionary.dictionary && dictionary.dictionary.name }}</td>
                        <td>{{ dictionary.description }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr/>
                  <h5>Фотографии</h5>
                  <div class="row">
                    <div class="col img-block" v-for="(img, index) in product.big_images" :key="index">
                      <feather-icon slot="icon-half" icon="CrossIcon" size="18"/>
                      <img :src="`/img/product/${img}`" class="product-img medium img-fluid mb-2" alt="">
                      <br>
                      <div v-if="img.includes('main')" class="mb-2">Главное фото</div>
                      <button v-else class="btn btn-outline-info btn-block" @click="setMainImage(index)">
                        Сделать главной
                      </button>
                      <button class="btn btn-outline-danger btn-block" @click="removeImage(index)">Удалить</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="card" v-if="product">
                <div class="card-body p-1">
                  <div class="row mb-2">
                    <div class="col align-self-center">
                      <h4>Спецификации</h4>
                    </div>
                    <div class="col text-right">
                      <router-link
                          :to="{ name: 'specification-create', params: { id: product.id } }"
                          class="btn btn-success"
                      >
                        Новая
                      </router-link>
                    </div>
                  </div>
                  <SpecificationDetails
                      :specifications="product.product_specifications"
                      :category-id="product.category_id"
                      @refresh="getProduct"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <AddImagesModal @update="getProduct"/>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import AddImagesModal from '@/views/products/AddImagesModal.vue';
import SpecificationDetails from '@/views/products/SpecificationDetails.vue';

export default {
  name: 'ProductDetails',
  props: ['tab'],
  directives: { Ripple },
  components: {
    AddImagesModal,
    SpecificationDetails,
  },
  data() {
    return {
      breadcrumbs: [],
      product: null,
      dictionaries: [],
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      await this.getProduct();
      this.dictionaries = (await this.$api.products.dictionaries(this.product.id)).data;
    },
    async setMainImage(index) {
      await this.$api.products.setMainImage(this.product.id, {
        image: this.product.big_images[index].split('/').pop(),
      });
      await this.getProduct();
    },
    async downloadImages() {
      try {
        await this.$api.products.downloadImages(this.product.id, `img_${this.product.id}.zip`);
      } catch (e) {
        // console.log(e.message);
      }
    },
    async getProduct() {
      this.product = (await this.$api.products.get(this.$route.params.id)).data;
    },
    async removeImage(index) {
      await this.$api.products.removeImage(this.product.id, {
        image: this.product.big_images[index].split('/').pop(),
      });
      this.product.big_images.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.product-img {
  width: auto;
}

.img-block {
  max-width: 200px !important;
  text-align: center;
}

.bottom-row {
  background-color: #ebeaff;
  border-bottom: 2px solid #CBCBCBFF;
}

.feather-info {
  color: #7367f0;
  cursor: pointer;
}

.size-popover {
  max-width: 500px;
}
</style>
